@import "bootstrap-it";

.hide-on-view[data-mode="view"], [data-mode="view"] .hide-on-view {
  display: none;
}

.hide-on-edit[data-mode="edit"], [data-mode="edit"] .hide-on-edit {
  display: none;
}

/* Use darkening of links to show interaction for fontawesome icons */
.icon-link {
  opacity: 0.5;
}

.icon-link:hover {
  text-decoration: none;
  opacity: 1;
}

/* active links for linked services */
.chat-link {
  background: url('/static/img/chat.png') no-repeat 0.2em center;
}

.chat-link:hover {
  background: theme-color("tag") url('/static/img/chat-night.png') no-repeat 0.2em center;
}

.gitlab-link {
  background: url('/static/img/codeshare.png') no-repeat 0.2em center;
}

.gitlab-link:hover {
  background: theme-color("tag") url('/static/img/codeshare-night.png') no-repeat 0.2em center;
}

.chat-link, .gitlab-link {
  background-size: 1.4rem;
  vertical-align: middle;
  padding: .5rem .75rem;
  color: theme-color("tag");
  border-radius: 0.25rem;
}

.chat-link:hover, .gitlab-link:hover {
  background-size: 1.4rem;
  vertical-align: middle;
  color: color-yiq(theme-color("tag"));
  text-decoration: none;
}

.chat-link:before {
  content: 'chat';
  font-weight: bold;
  margin-left: 1rem;
}

.gitlab-link:before {
  content: 'codeshare';
  font-weight: bold;
  margin-left: 1rem;
}

.chat-link:after, .gitlab-link:after {
  content: '.phy'
}
